"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.load = void 0;

var _actions = require("./State/Ducks/GlobalLogin/actions");

var _functions = require("./Utils/functions");

var _events = _interopRequireDefault(require("./Utils/events"));

var _analytics = require("./Utils/analytics");

var _this = void 0;

var getComponent = function getComponent(name) {
  switch (name) {
    case 'LoginContainer':
      return import('./Views/GlobalLogin/AuthLogin');

    default:
      throw Error('Login Component does not exist');
  }
};

var render = function render(config, store, elem) {
  var name = config.get('name');

  if (!name || !elem) {
    return;
  }

  var analyticsUtil = new _analytics.Analytics();
  getComponent(name).then(function (exports) {
    (0, _functions.renderComponent)(exports.default, store, config, elem, analyticsUtil);
  }).then(function () {
    window.Jetstar = window.Jetstar || {};
    window.Jetstar.features = window.Jetstar.features || {};
    window.Jetstar.features.globalLogin = window.Jetstar.features.globalLogin || {};
    window.Jetstar.features.globalLogin = {
      signIn: function signIn(pid) {
        _events.default.dispatch('auth0Login', {
          pid: pid
        });
      },
      signUp: function signUp(pid) {
        _events.default.dispatch('auth0Signup', {
          pid: pid
        });
      }
    };
  });
};

var loadComponents = (0, _functions.loaderComponents)(function () {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  render.apply(_this, args);
});

var loadGlobalLogin = function loadGlobalLogin() {
  return function () {
    for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    var store = args[0],
        schemaName = args[1],
        elem = args[2],
        sourceIdentifier = args[3],
        moduleComponents = args[4];
    store.dispatch((0, _actions.init)());
    var state = store.getState();
    var schema = state.schema;
    var moduleConfig = schema.get(sourceIdentifier);
    loadComponents(moduleConfig, schemaName, elem, store, moduleComponents);
  };
};

var load = loadGlobalLogin();
exports.load = load;