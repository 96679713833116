"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var CustomEventEmitter = {
  events: {},
  dispatch: function dispatch(event, data) {
    if (this.events[event]) {
      this.events[event].forEach(function (callback) {
        return callback(data);
      });
    }
  },
  subscribe: function subscribe(event, callback) {
    if (!this.events[event]) {
      this.events[event] = [];
    }

    this.events[event].push(callback);
  },
  unsubscribe: function unsubscribe(event) {
    if (this.events && this.events[event]) {
      delete this.events[event];
    }
  }
};
var _default = CustomEventEmitter;
exports.default = _default;